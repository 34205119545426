<template>
    <div class="p-[16px_20px] border-b-[1px] border-b-[#E2E4E9]">
        <span class="block text-base text-[#0A0D14] font-medium mb-[20px]">Delete this list?</span>
        <span class="block text-base text-[#4D5761]">“{{ store.currentList.name }}” will be permanently deleted.</span>
    </div>
    <div class="p-[16px_20px] flex gap-[12px]">
        <button class="button-secondary w-full" @click="onCancel()">Cancel</button>
        <button class="button-primary w-full" @click="onDelete()">Delete</button>
    </div>
</template>
<script>
import useListStore from '@/stores/List';

export default {
    setup: () => ({
        store: useListStore()
    }),
    props: {
        interface: {
            type: Object,
            required: true
        },
        params: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.interface.modal.setHeader();
    },
    methods: {
        onCancel() {
            CURRENT_PAGE == "lists#index"
                ? this.interface.modal.close()
                : this.interface.modal.setView('ModalListSettings');
        },
        async onDelete() {
            await this.store.deleteList(this.store.currentList.id);
            
            if (CURRENT_PAGE == "lists#index") {
                this.interface.notify('The list was deleted successfully.');
                this.interface.modal.close();
            } else {
                window.location.href = "/profile/lists";
            }
        }
    }
}
</script>